<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <div class="admins-navbar">
          <md-tabs>
            <md-tab
              id="tab-stat-paid"
              md-label="Сумма ставок в Слотах за сутки"
            />
            <md-tab
              id="tab-stat-all-paid"
              md-label="Сумма ставок - выигрыш игроков"
            />
            <md-tab id="tab-stat-jackpot" md-label="Текущий Джекпот в слотах" />
            <md-tab id="tab-stat-auths" md-label="Количество авторизаций" />
            <md-tab
              id="tab-stat-duration"
              md-label="Общая длительность сессий"
            />
            <md-tab
              id="tab-stat-re-enter"
              md-label="Количество повторных посещений"
            />
          </md-tabs>
        </div>
        <md-card-content>
          <Chart :series="series" />
          <md-button @click="updateSeries()"> Поменять данные </md-button>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import Chart from "@/components/ui/Chart.vue";

export default {
  components: { Chart },
  data() {
    return {
      series: [],
    };
  },
  mounted() {
    this.updateSeries();
  },
  methods: {
    updateSeries() {
      this.$set(this, "series", []);
      const res = [];
      for (let i = 0; i < 31; i++) {
        res.push(Math.floor(Math.random() * 100));
      }
      this.series.push(res);
    },
  },
};
</script>

<style lang="scss" scoped>
.admins-wrap {
  padding-top: 40px;
}

.admins-navbar {
  margin-bottom: 20px;

  &::v-deep {
    .md-tabs-navigation {
      background-color: #58b05c !important;
      padding: 20px;
    }
    .md-tabs-navigation {
      margin: -10px 15px 0;
    }
  }
}
</style>
