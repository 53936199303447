<template>
  <div class="ct-chart ct-perfect-fourth"></div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    series: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: {
        labels: [
          "01.01.2023",
          "02.01.2023",
          "03.01.2023",
          "04.01.2023",
          "05.01.2023",
          "06.01.2023",
          "07.01.2023",
          "08.01.2023",
          "09.01.2023",
          "10.01.2023",
          "11.01.2023",
          "12.01.2023",
          "13.01.2023",
          "14.01.2023",
          "15.01.2023",
          "16.01.2023",
          "17.01.2023",
          "18.01.2023",
          "19.01.2023",
          "20.01.2023",
          "21.01.2023",
          "22.01.2023",
          "23.01.2023",
          "24.01.2023",
          "25.01.2023",
          "26.01.2023",
          "27.01.2023",
          "28.01.2023",
          "29.01.2023",
          "30.01.2023",
          "31.01.2023",
          "01.02.2023",
        ],
        series: [],
      },
      options: {
        fullWidth: true,
        chartPadding: {
          right: 60,
        },
        axisY: {
          onlyInteger: true,
        },
        axisX: {
          labelInterpolationFnc: (value, index) => {
            const currentDay = moment(value, "DD.MM.YYYY");
            if (index == 0) {
              return currentDay.format("DD.MM.YYYY");
            }
            const endMonth = moment(value, "DD.MM.YYYY").endOf("month");
            return currentDay.isValid() &&
              endMonth.isValid() &&
              currentDay.date() == endMonth.date()
              ? currentDay.format("MMMM")
              : null;
          },
        },
      },
      dataDonut: {
        series: [10, 20, 30, 100],
      },
    };
  },
  watch: {
    series(value) {
      this.data.series = value;
      this.$Chartist.Line(".ct-chart", this.data, this.options);
    },
  },
  mounted() {
    this.data.series = this.series;
    this.$Chartist.Line(".ct-chart", this.data, this.options);
    // this.$Chartist.Pie(".ct-chart", this.dataDonut, this.optionsDonut);
  },
};
</script>
